import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router";
import {
  deleteClient,
  getClientCampaignsList,
  getClientDetails,
  getClientInvitationStatus,
  getClientMeetings,
  getClientPayments,
  getClients,
} from "../../apis/clients.api";
import { AppAlertBox } from "../../components/modals/alert-box";
import { DEFAULT_SELECTED_PAGE_SIZE, GLOBAL_TEXT } from "../../constants";
import { convertToUrl } from "../../services/utils";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { resetClientStore } from "../../redux/modules/clients/actions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { animateScroll as scroll } from "react-scroll";
import { ROUTE_PATHS } from "../../routes/route-paths";
import { AddCampaignModal } from "./client-details/campaign/add-campaign-modal";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import MigrateVendorModal from "./list/MigrateVendorModal";

const clientHOC = (ComposedComponent) => {
  class ClientHOC extends React.Component<any, any> {
    constructor(props) {
      super(props);

      this.state = {
        showDeleteConfirm: false,
        showMigrateModal: false,
        selectedId: null,
        anchorEl: null,
        showAddNewCampaignModal: false,
        selectedRow: null,
      };
    }

    componentWillUnmount(): void {
      if (
        !this.props.history.location.pathname.startsWith(
          ROUTE_PATHS.CLIENTS.ROOT
        )
      ) {
        this.props.dispatchResetClientStore();
      }
    }

    onAddClientClick = () => {
      this.props.history.push(ROUTE_PATHS.CLIENTS.ADD);
    };

    onGetClientDetails = (id) => {
      this.props.dispatchGetClientDetails(id);
    };

    getClientsList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      searchValue = "",
    } = {}) => {
      this.props.dispatchGetClientList({
        selectedPage,
        selectedPageSize,
        searchValue,
      });

      scroll.scrollToTop();
    };

    getClientMeetingsList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      clientId = this.props.selectedClient.id,
    } = {}) => {
      this.props.dispatchGetClientMeetingsList({
        selectedPage,
        selectedPageSize,
        clientId,
      });
    };

    getClientInvitationStatusList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      clientId = this.props.selectedClient.id,
      selectedDate = "",
    } = {}) => {
      this.props.dispatchGetClienInvitationStatusList({
        selectedPage,
        selectedPageSize,
        clientId,
        selectedDate,
      });
    };

    getClientPaymentsList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      clientId = this.props.selectedClient.id,
    } = {}) => {
      this.props.dispatchGetClientPaymentsList({
        selectedPage,
        selectedPageSize,
        clientId,
      });
    };

    getClientCampaignsList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      clientId = this.props.selectedClient.id,
    } = {}) => {
      this.props.dispatchGetClientCampaignsList({
        selectedPage,
        selectedPageSize,
        clientId,
      });
    };

    onEditClick = (id) => {
      const path = convertToUrl(ROUTE_PATHS.CLIENTS.EDIT, {
        ":id": id,
      });

      this.props.history.push(path);
    };

    onDeleteClick = (id) => {
      this.setState({
        ...this.state,
        showDeleteConfirm: true,
        selectedId: id,
        anchorEl: null,
      });
    };

    onViewlick = (id) => {
      const path = convertToUrl(ROUTE_PATHS.CLIENTS.DETAILS, {
        ":id": id,
        ":tab": "info",
      });

      this.props.history.push(path);
    };

    onMigrateClick = (id) => {
      this.setState({
        ...this.state,
        showMigrateModal: true,
        selectedId: id,
        anchorEl: null,
      });
    };

    onDeleteConfirm = async () => {
      const response = await deleteClient(this.state.selectedId);

      this.setState(
        {
          ...this.state,
          selectedId: null,
          showDeleteConfirm: false,
        },
        () => {
          this.props.history.push(ROUTE_PATHS.CLIENTS.LIST);
        }
      );
    };

    togglePopoverClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      id: any,
      row?: any
    ) => {
      this.setState({
        ...this.state,
        anchorEl: this.state.anchorEl ? null : event.currentTarget,
        selectedId: id,
        selectedRow: row
      });
    };

    onAddNewCampaignClick = () => {
      this.setState({
        showAddNewCampaignModal: true,
      });
    };

    handleModalClose = () => {
      this.setState({
        showAddNewCampaignModal: false,
        showMigrateModal: false,
      });
    };

    render() {
      const open = Boolean(this.state.anchorEl);
      const popoverId = open ? "client-list-action-items" : undefined;

      return (
        <>
          <ComposedComponent
            onViewClick={this.onViewlick}
            onDeleteClick={this.onDeleteClick}
            onEditClick={this.onEditClick}
            onAddClientClick={this.onAddClientClick}
            toggleMenuOptions={this.togglePopoverClick}
            getClientList={this.getClientsList}
            getClientMeetingsList={this.getClientMeetingsList}
            getClientPaymentsList={this.getClientPaymentsList}
            getClientCampaignsList={this.getClientCampaignsList}
            getClientDetails={this.onGetClientDetails}
            getClientInvitationStatusList={this.getClientInvitationStatusList}
            onAddNewCampaignClick={this.onAddNewCampaignClick}
            clientList={this.props.list}
            clientMeetingsList={this.props.clientMeetingsList}
            clientPaymentsList={this.props.clientPaymentsList}
            clientCampaignsList={this.props.clientCampaignsList}
            clientDetails={this.props.selectedClient}
            clientInvitationStatusList={this.props.clientInvitationStatusList}
            {...this.props}
          />

          <Menu
            autoFocus={false}
            id={popoverId}
            open={open}
            anchorEl={this.state.anchorEl}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            onClose={this.togglePopoverClick}
          >
            <MenuItem onClick={() => this.onViewlick(this.state.selectedId)}>
              <ListItemIcon>
                <VisibilityIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.onEditClick(this.state.selectedId)}>
              <ListItemIcon>
                <BorderColorIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => this.onMigrateClick(this.state.selectedId)}
            >
              <ListItemIcon>
                <SwapHorizontalCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Transfer Management Rights</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.onDeleteClick(this.state.selectedId)}>
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>

          <AppAlertBox
            isModalOpen={this.state.showDeleteConfirm}
            onConfirm={this.onDeleteConfirm}
            onCancel={() =>
              this.setState({
                ...this.state,
                selectedId: null,
                showDeleteConfirm: false,
              })
            }
            message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_DELETE}
          />

          {this.state.showAddNewCampaignModal && (
            <AddCampaignModal
              selectedClient={this.props.selectedClient}
              handleModalClose={this.handleModalClose}
            />
          )}

          {this.state.showMigrateModal && (
            <MigrateVendorModal
              selectedClient={this.state.selectedRow}
              handleModalClose={this.handleModalClose}
            />
          )}
        </>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      list: state.clients.list,
      selectedClient: state.clients.selectedClient,
      clientMeetingsList: state.clients.info.meetings,
      clientPaymentsList: state.clients.info.payments,
      clientCampaignsList: state.clients.info.campaigns,
      clientInvitationStatusList: state.clients.info.invitationStatus,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatchGetClientList: (payload) => dispatch(getClients(payload)),
      dispatchGetClientMeetingsList: (payload) =>
        dispatch(getClientMeetings(payload)),
      dispatchGetClienInvitationStatusList: (payload) =>
        dispatch(getClientInvitationStatus(payload)),
      dispatchGetClientPaymentsList: (payload) =>
        dispatch(getClientPayments(payload)),
      dispatchGetClientCampaignsList: (payload) =>
        dispatch(getClientCampaignsList(payload)),
      dispatchDeleteClient: (id) => dispatch(deleteClient(id)),
      dispatchGetClientDetails: (id) => dispatch(getClientDetails(id)),
      dispatchResetClientStore: () => dispatch(resetClientStore()),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientHOC));
};

export default clientHOC;
