import { isDesktop } from "react-device-detect";
import { SquareChip } from "../../../../components/chips/square-chip";
import { CONST_PROJECT_STATUS_LIST } from "../../../../constants/lists";
import { getProjectStatus } from "../../../../services/utils";

function ProjectStatus({ data, labelField = "label" }) {
  const status = getProjectStatus(data);

  return (
    <SquareChip
      text={CONST_PROJECT_STATUS_LIST[status]?.[labelField]}
      color={"white"}
      bgColor={CONST_PROJECT_STATUS_LIST[status]?.bgColor}
      fontWeight={"bolder"}
      fontSize={10}
      sx={{width: "100%"}}
    />
  );
}

export { ProjectStatus };
