import React, { useState } from "react";
import { AppModalDialogBox } from "../../../components/modals";
import { SaveAndCancelButton } from "../../../components/buttons";
import { Alert, Box, Typography } from "@mui/material";
import { LabelAndValue } from "../../../components/typography";
import { AppTextField } from "../../../components/textfields";
import { useDispatch } from "react-redux";
import { submitMigrateVendor } from "../../../apis/clients.api";
import { GLOBAL_TEXT, REGEX } from "../../../constants";
import { AppAlertBox } from "../../../components/modals/alert-box";

export default function MigrateVendorModal({
  selectedClient,
  handleModalClose,
}) {
  const [to, setTo] = useState("");
  const [error, setError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setError("");
    const text = e.target.value;
    setTo(e.target.value);
  };

  const onSubmit = async (isAgreed = "") => {
    if (
      !REGEX.EMAIL.test(to) ||
      to?.toLowerCase().match(/gmail|hotmail|outlook|yahoo/g)
    ) {
      if (!to) {
        setError("Please enter email");
        return;
      }

      setError("Please enter valid corporate email");
      return;
    }
    if (isAgreed !== "ok") {
      setShowConfirmation(true);
      return;
    }
    const response: any = await dispatch(
      submitMigrateVendor({ from: selectedClient?.email, to })
    );

    if (response.success) {
      handleModalClose();
    }
  };

  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Transfer Management Rights"
      handleClose={handleModalClose}
      maxHeight={600}
      actionButtonRenderer={
        <SaveAndCancelButton
          primaryText={"Submit"}
          defaultText={"Cancel"}
          onPrimaryActionClick={onSubmit}
          onDefaultButtonClick={handleModalClose}
        />
      }
      size={"sm"}
    >
      <Box display={"flex"} flexDirection="column">
        <Alert severity="info" sx={{ mb: 2, fontSize: 12 }}>
          <strong>Note:</strong> Enter the email address of the person to whom
          you want to transfer full management rights of the Connect Platform.
          Once they sign up, you will no longer have access to the Connect
          Platform.
        </Alert>
        <Typography variant="caption">From: {selectedClient?.email}</Typography>

        <AppTextField
          id={"to"}
          name={"to"}
          label={"To"}
          onChange={handleOnChange}
          value={to}
          placeholder={"Enter email"}
          error={error}
          autoComplete={"off"}
          rootStyle={{ marginTop: 10 }}
        />
      </Box>
      {showConfirmation && (
        <AppAlertBox
          isModalOpen={true}
          onConfirm={() => {
            onSubmit("ok");
            setShowConfirmation(false);
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
          message={"Are your sure you want to proceed?"}
        />
      )}
    </AppModalDialogBox>
  );
}
