import { AppModalDialogBox } from "../../../../components/modals";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  concatSelectedFromToTime,
  formatYYYYMMDDDate,
  getGroupTimesByDate,
} from "../../../../services/date-and-time.utils";
import TodayIcon from "@mui/icons-material/Today";

export default function AmbassadorSuggestedSlots({ data, onClose }) {
  const times = data?.suggestedTimeSlots;
  const selectedSlot = data?.selectedTimeSlot;

  function renderSuggestedTimeSlots() {
    if (times?.length) {
      return <SuggestedSlots selectedSlot={selectedSlot} times={times} />;
    }

    if (data?.isCalendly) {
      return (
        <Typography
          fontSize={13}
          textAlign={"center"}
          mt={2}
          mb={2}
          color={"primary.main"}
        >
          Time slot suggested via Calendly
        </Typography>
      );
    }
  }

  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Slots"
      handleClose={onClose}
      size={"md"}
      actionButtonRenderer={<Button onClick={onClose}>Close</Button>}
    >
      {renderSuggestedTimeSlots()}

      {selectedSlot && <SelectedSlot slot={selectedSlot} />}
    </AppModalDialogBox>
  );
}

function SuggestedSlots({ selectedSlot, times }) {
  const groupedByDate = getGroupTimesByDate(times);
  const selectedDate = formatYYYYMMDDDate(selectedSlot);

  return (
    <>
      <Typography fontSize={13} mb={1} color={"grey"}>
        Suggested Slots:
      </Typography>
      <Box
        mb={2}
        display={"flex"}
        component={Paper}
        maxHeight={"300px"}
        sx={{ overflowY: "auto" }}
      >
        {Object.keys(groupedByDate)?.map((time) => (
          <Box minWidth={"25%"}>
            <List
              dense
              subheader={
                <ListSubheader>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    color={selectedDate === time && "green"}
                  >
                    <TodayIcon />
                    {time}
                  </Box>{" "}
                </ListSubheader>
              }
              key={time}
            >
              {groupedByDate[time]?.map((t, i) => (
                <ListItem
                  sx={{ color: selectedSlot?.from == t?.from && "green" }}
                >
                  <ListItemAvatar sx={{ minWidth: 30 }}>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        color: "white",
                        bgcolor: selectedSlot?.from == t?.from && "green",
                      }}
                    >
                      <AccessTimeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={concatSelectedFromToTime(t, true)} />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </>
  );
}

function SelectedSlot({ slot }) {
  return (
    <Box mt={2}>
      <Typography>
        <Typography fontSize={13} color={"grey"}>
          {" "}
          Selected Slot:{" "}
        </Typography>{" "}
        {concatSelectedFromToTime(slot)}
      </Typography>
    </Box>
  );
}
