import { getApi, postApi, putApi } from "../services/axios";
import APIs from ".";
import {
  setInvitationsList,
  setInvitationListFilters,
  setInvitationsListLoading,
} from "../redux/modules/invitations/actions";
import { ENUM_PROJECT_STATUS } from "../enums";

const INVITATION_LIST_STATSUSES = [
  ENUM_PROJECT_STATUS.DONE,
  ENUM_PROJECT_STATUS.SEND_MEETING_LINK,
  ENUM_PROJECT_STATUS.ARCHIVE,
  ENUM_PROJECT_STATUS.DONE_BY_AMBASSADOR,
  ENUM_PROJECT_STATUS.DONE_BY_CLIENT,
];

function getMofifiedStatus(status) {
  if (status?.includes(ENUM_PROJECT_STATUS.SEND_MEETING_LINK)) {
    if (Array.isArray(status)) {
      return status
        ?.join(",")
        ?.replace(
          ENUM_PROJECT_STATUS.SEND_MEETING_LINK,
          `${ENUM_PROJECT_STATUS.SEND_MEETING_LINK},${ENUM_PROJECT_STATUS.DONE_BY_AMBASSADOR},${ENUM_PROJECT_STATUS.DONE_BY_CLIENT}`
        );
    } else {
      return status?.replace(
        ENUM_PROJECT_STATUS.SEND_MEETING_LINK,
        `${ENUM_PROJECT_STATUS.SEND_MEETING_LINK},${ENUM_PROJECT_STATUS.DONE_BY_AMBASSADOR},${ENUM_PROJECT_STATUS.DONE_BY_CLIENT}`
      );
    }
  } else if (status === "" || (Array.isArray(status) && status.length === 0)) {
    return INVITATION_LIST_STATSUSES?.join(",");
  }

  return status;
}

export function getInvitations({
  selectedPage,
  selectedPageSize,
  searchValue,
  selectedDate,
  status = "",
}) {
  return async function (dispatch) {
    const tStatus = getMofifiedStatus(status);
    const res = await getApi(
      APIs.INVITATIONS.GET_INVITATION_LIST(
        selectedPage,
        selectedPageSize,
        searchValue,
        selectedDate,
        tStatus
      ),
      {
        validateUser: true,
        loadingFn: setInvitationsListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setInvitationsList({
          ...res.data,
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setInvitationListFilters({
          selectedPage,
          selectedPageSize,
          searchValue,
          selectedDate,
          status,
        })
      );
    }

    return res;
  };
}

export function submitInvitationApproval({ id, type, data }) {
  return async function (dispatch) {
    const res = await postApi(
      APIs.INVITATIONS.SUMIT_INVITATION_APPROVAL(id, type),
      data,
      {
        validateUser: true,
      }
    );

    return res;
  };
}

export function updateInvitation(payload) {
  return async function (dispatch) {
    const { id, additionalMembers, updatedTimeSlot } = payload;

    const res = await putApi(
      APIs.INVITATIONS.UPDATE_INVITATION({ id }),
      {
        ...(additionalMembers && { additionalMembers }),
        ...(updatedTimeSlot && { updatedTimeSlot }),
      },
      {
        validateUser: true,
      }
    );

    return res;
  };
}

export function submitArchiveMeeting(payload) {
  return async function (dispatch) {
    const { id, action } = payload;

    const res = await postApi(
      APIs.INVITATIONS.ARCHIEVE_MEETING({ id }),
      { action },
      {
        validateUser: true,
      }
    );

    return res;
  };
}
