import { IconButton, Typography } from "@mui/material";
import { Image } from "../../../components/images";
import { InitialNameAvatar } from "../../../components/images/initial-name-avatar";
import { SimpleTable } from "../../../components/mui/table";
import { ENUM_CURRENCY, RENDERER_TYPES } from "../../../enums";
import { convertToFullName, numberFormat } from "../../../services/utils";
import SvgIcons from "../../../svgs";
import { useState } from "react";

function ClientListTable({
  list,
  columns,
  handlePageFilters,
  toggleMenuOptions,
  appliedFilters,
  onShowViewCountDetailsClick,
}: any) {
  const { selectedPage, selectedPageSize } = appliedFilters;

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_NAME_WITH_AVATAR:
        return (
          <InitialNameAvatar
            text={convertToFullName(row.firstName, row.lastName)}
            name={row.firstName}
          />
        );
      case RENDERER_TYPES.AMOUNT:
        return numberFormat(row[column.field], ENUM_CURRENCY.USD);
      case RENDERER_TYPES.ACTION:
        switch (column.field) {
          case "viewCount":
            if (!row?.[column?.field]) return "-";

            return (
              <Typography
                component={"a"}
                href="#"
                onClick={(e) => {
                  e?.preventDefault();
                  onShowViewCountDetailsClick(row);
                }}
              >
                {numberFormat(row?.[column?.field])}
              </Typography>
            );
          default:
            return (
              <IconButton onClick={(e) => toggleMenuOptions(e, row.id, row)}>
                <img src={SvgIcons.Circles} />
              </IconButton>
            );
        }

      default:
        break;
    }
  };

  return (
    <SimpleTable
      tdRenderer={handleTDRenderer}
      rows={list?.data}
      fetching={list?.loading}
      columns={columns}
      total={list?.total}
      onPageSettingsChange={handlePageFilters}
      selectedPage={selectedPage}
      selectedPageSize={selectedPageSize}
    ></SimpleTable>
  );
}

export { ClientListTable };
